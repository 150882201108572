import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Home.css';

// Data for reviews and sections
const reviews = [
  "Fast and reliable! My PS5 is working like new again. – John D.",
  "Exceptional service. Highly recommended! – Sarah T.",
  "Affordable prices and quick turnaround. – Emily R."
];

const sections = [
  {
    title: "About Us",
    content: "High-quality repair services for all your devices, ensuring your gadgets are working like new!"
  },
  {
    title: "Our Story",
    content: "Founded in 2024, we started as a local service and grew into a trusted name across the community."
  },
  {
    title: "Our Mission",
    content: "We’re committed to providing fast, reliable, and affordable repair services for customer satisfaction."
  }
];

// Rotating Reviews Component
const RotatingReviews = ({ reviews }) => {
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [reviews]);

  return (
    <div className="reviews-section">
      <h2>What Our Customers Say</h2>
      <p className="review" aria-live="polite">{reviews[currentReview]}</p>
    </div>
  );
};

RotatingReviews.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.string).isRequired
};

// Section Component
const Section = ({ title, content }) => (
  <div className="section">
    <h2>{title}</h2>
    <p>{content}</p>
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

// Main Home Component
const Home = () => {
  return (
    <div className="home-container">
      <p className="home-description">
        We specialize in repairing phones, laptops, gaming consoles, and custom modifications.
      </p>
      {sections.map((section, index) => (
        <Section key={index} title={section.title} content={section.content} />
      ))}
      <RotatingReviews reviews={reviews} />
    </div>
  );
};

export default Home;
